import $ from 'jquery';

import Ticker from 'JIX/ui/Ticker.js';

const stashes = window.Stash['widgets/vertical_ticker'];

function init(id, stash) {
    const container = document.getElementById(id);
    new Ticker(container, stash);
}

$(() => {
    stashes.forEach(({ id, stash }) => init(id, stash));
});
